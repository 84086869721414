<template>
  <main class="plan-body">
    <PaywallComponent />
  </main>
</template>

<script>
import PaywallComponent from '../components/Paywall';

export default {
  name: 'Paywall',
  computed: {
  },
  components: {
    PaywallComponent
  }
};
</script>

<style lang="scss" scoped>
  body {
    background-color: #c6d0d8;
  }
</style>
