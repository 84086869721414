<template>
   <div class="d-flex justify-between align-center mb-32">
    <a id="paywall-logo" href="/" class="d-flex justify-between align-center typography-15">
      <div><img class="mr-24" :src="require('../assets/images/logo.svg')" alt="logo" title="GamayunLogo"></div>
      <span>GAMAYUN</span>
    </a>
    <div class="d-flex">
      <button id="monthly-check" class="mr-24 typography-13 opacities" @click="monthlyCheck" :class="!toggle ? 'active' : ''">
        Monthly
      </button>
      <input type="checkbox" class="checkbox" id="checkbox" ref="checkbox" v-model="toggle" />
      <label for="checkbox"></label>
      <button id="yearly-check" class="typography-13 opacities" @click="yearlyCheck" :class="toggle ? 'active' : ''">
        Yearly
      </button>
    </div>

  </div>
  <div class="plan-wrap row mb-24">
    <div class="col col-4">
      <div class="plan-block">
        <div class="d-flex justify-between align-center blacked pad">
        <div class="plan-block__head color-link typography-14">LIGHT</div>
        <div class="plan-block__head color-link typography-14">
          <span v-if="!toggle">${{ light.price }}&ensp;/ month</span>
        </div>
        </div>
          <div class="plan-block__body body-1">
            <div class="typography-theme d-flex column let-space-04 mb-12">
              <div class="typography-11 d-flex justify-between align-center let-space-04">
                <span v-if="!toggle">Monthly package</span>
                <span v-else>Yearly package</span>
                <span v-if="!toggle">{{ light.points }} slp</span>
                <span v-else>Not available</span>
              </div>
<!--              <ul class="typography-8 color-1 let-space-08 wid mt-16">-->
<!--                <li class="d-flex justify-between align-center"><span>Case amount</span><span>10</span></li>-->
<!--                <li class="d-flex justify-between align-center"><span>Tag amount in case</span><span>10</span></li>-->
<!--                <li class="d-flex justify-between align-center"><span>Search history</span><span>10</span></li>-->
<!--              </ul>-->
            </div>
            <div v-html="light.description"></div>
            <div class="plan-button">
              <a :href="link" target="_blank" id="buy-light-plan">
                <button
                  class="opacity-btn"
                  @click="goToPay('light')"
                  :disabled="toggle">
                  {{ toggle ? 'NOT AVAILABLE' : 'BUY LIGHT PLAN'}}
                </button>
              </a>
            </div>
          </div>
      </div>
    </div>
    <div class="col col-4">
      <div class="plan-block">
        <div class="d-flex justify-between align-center blacked pad">
        <div class="plan-block__head color-link typography-14">BASE</div>
        <div class="plan-block__head color-link typography-14">
          <span v-if="toggle" class="throught">${{ baseMonth.price }}</span>
          <span v-if="!toggle">${{ baseMonth.price }}&ensp;</span>
          <span v-if="toggle">${{ baseYear.price / 12 }}&ensp;</span>
          / month
        </div>
        </div>
          <div class="plan-block__body body-2">
            <div class="typography-theme d-flex column let-space-04 mb-12">
              <div class="typography-11 d-flex justify-between align-center let-space-04">
                <span v-if="!toggle">Monthly package</span>
                <span v-else>Yearly package</span>
                <span v-if="!toggle">{{ baseMonth.points }} slp</span>
                <span v-else>{{ baseYear.points }} slp</span>
              </div>
<!--              <ul class="typography-8 color-1 let-space-08 wid mt-16">-->
<!--                <li class="d-flex justify-between align-center"><span>Case amount</span><span>30</span></li>-->
<!--                <li class="d-flex justify-between align-center"><span>Tag amount in case</span><span>20</span></li>-->
<!--                <li class="d-flex justify-between align-center"><span>Search history</span><span>40</span></li>-->
<!--              </ul>-->
            </div>
            <div v-html="(!toggle) ? baseMonth.description : baseYear.description"></div>
            <div class="plan-button">
              <a :href="link" target="_blank" id="buy-base-plan">
                <button class="primary-btn" @click="goToPay(`base_${toggle ? 'year' : 'month'}`)">
                  BUY BASE PLAN
                </button>
              </a>
            </div>
          </div>
      </div>
    </div>
    <div class="col col-4">
      <div class="plan-block">
        <div class="d-flex justify-between align-center blacked pad">
         <div class="plan-block__head color-link typography-14">
          <span>ENTERPRISE</span>
         </div>
        </div>
          <div class="plan-block__body body-3">
            <div class="typography-theme d-flex column let-space-04 mb-12">
             <div class="typography-11 d-flex justify-between align-center let-space-04">
               <span v-if="!toggle">Monthly package</span>
               <span v-else>Yearly package</span>
               <span>custom</span>
             </div>
<!--              <ul class="typography-8 color-1 let-space-08 wid mt-16">-->
<!--                <li class="d-flex justify-between align-center "><span>Case amount</span><span>custom</span></li>-->
<!--                <li class="d-flex justify-between align-center "><span>Tag amount in case</span><span>custom</span></li>-->
<!--                <li class="d-flex justify-between align-center "><span>Search history</span><span>custom</span></li>-->
<!--              </ul>-->
            </div>
            <p class="subscription-info typography-8 color-1 let-space-08">
              Customize and automate your investigations. The best decision for frequent research and collaborative work.
            </p>
            <div class="typography-theme d-flex column let-space-04">
              <ul class="typography-8 color-1 let-space-08">
                <li class="d-flex mb-8">
                  <div class="mr-10">
                    <img :src="require('../assets/icons/check-mark.svg')" alt="">
                  </div>
                  All LIGHT and BASE features
                </li>
                <li class="d-flex mb-8">
                  <div class="mr-10">
                    <img :src="require('../assets/icons/check-mark.svg')" alt="">
                  </div>
                  View activity of target individual. Monitoring in social networks
                </li>
                <li class="d-flex mb-8">
                  <div class="mr-10">
                    <img :src="require('../assets/icons/check-mark.svg')" alt="">
                  </div>
                  Discover individuals by Event
                </li>
                <li class="d-flex mb-8">
                  <div class="mr-10">
                    <img :src="require('../assets/icons/check-mark.svg')" alt="">
                  </div>
                  Dedicated support
                </li>
                <li class="d-flex mb-8">
                  <div class="mr-10">
                    <img :src="require('../assets/icons/check-mark.svg')" alt="">
                  </div>
                  SLA
                </li>
              </ul>
            </div>
            <div class="plan-button">
              <button id="paywall-contact-us" class="opacity-btn" @click="openModal = true">CONTACT US</button>
            </div>
          </div>
      </div>
    </div>
  </div>
  <div class="d-flex justify-between mt-45 mb-32">
    <div>
      <div class="paywall-social-icons black d-flex align-center">
          <a
            class="paywall-social-icons__youtube d-flex mr-20"
            href="https://www.youtube.com/channel/UCcFD986JKvXXU88w0SRfvow"
            target="_blank"
          >
            <Icon id="youtube" name="youtube" color="#111"/>
          </a>
          <a
            class="paywall-social-icons__twitter d-flex mr-20 pt-2"
            href="https://twitter.com/_SocialLinks_"
            target="_blank"
          >
            <Icon id="twitter" name="twitter" color="#111"/>
          </a>
          <a
            class="paywall-social-icons__linkedin d-flex mr-26"
            href="https://www.linkedin.com/company/10294269/"
            target="_blank"
          >
            <Icon id="linkedin" name="linkedin" color="#111"/>
          </a>
          <a
            class="paywall-social-icons__facebook d-flex"
            href="https://www.facebook.com/Social-Links-Maltego-Transforms-810934209023184"
            target="_blank"
          >
            <Icon id="facebook" name="facebook" color="#111"/>
          </a>
      </div>
    </div>
    <button
      id="paywall-back"
      class="opacity-btn footer-btn mt-n15"
      v-if="prevRoute === '/plan' || $store.state.authAndRegistration.account.subscription || prevRoute === '/'"
      @click="$router.back()"
    >
      Back
    </button>
    <span class="typography-11">
      Any questions? <a id="paywall-write-to-us" class="typography-11 blued" @click="openModal = true">Write to us</a>
    </span>
  </div>
  <SendModal v-if="openModal" @closeModal="openModal = false" />
</template>

<script>
import Icon from './app/Icon';
import SendModal from './app/SendModal';

export default {
  name: 'PaywallComponent',
  components: {
    Icon,
    SendModal
  },
  data () {
    return {
      toggle: false,
      openModal: false,
      products: [],
      link: '',
      prevRoute: window.history.state.back
    };
  },
  computed: {
    baseMonth () {
      return this.products.find(plan => plan.code === 'base_month');
    },
    baseYear () {
      return this.products.find(plan => plan.code === 'base_year');
    },
    light () {
      return this.products.find(plan => plan.code === 'light');
    }
  },
  methods: {
    goToPay (value) {
      this.link = this.products.find(i => i.code === value).checkout_url;
    },
    monthlyCheck () {
      this.$refs.checkbox.checked = false;
      this.toggle = false;
    },
    yearlyCheck () {
      this.$refs.checkbox.checked = true;
      this.toggle = true;
    },
    setStyles (element) {
      element.className = '';
      element.removeAttribute('style');
      element.style.fontWeight = '600';
      element.style.fontSize = '14px';
      element.style.lineHeight = '24px';
      element.style.letterSpacing = '0.08em';
      element.style.color = '#000000';
    }
  },
  beforeMount () {
    this.products = JSON.parse(JSON.stringify(this.$store.state.products.loadedProducts));
    this.products.forEach(product => {
      product.price = parseInt(product.price);
      product.points = product.points.toLocaleString();
      const description = product.description;
      const descriptionContainer = document.createElement('div');
      this.setStyles(descriptionContainer);
      descriptionContainer.innerHTML = description;
      const p = descriptionContainer.querySelector('p');
      if (p) {
        this.setStyles(p);
        p.classList.add('subscription-info', 'typography-8', 'let-space-08', 'mb-24');
        const span = p.querySelector('span');
        if (span) this.setStyles(span);
      }
      const ul = descriptionContainer.querySelector('ul');
      if (ul) ul.classList.add('typography-8', 'let-space-08');
      const listRows = descriptionContainer.querySelectorAll('li');
      listRows.forEach(li => {
        this.setStyles(li);
        li.classList.add('mb-8', 'd-flex');
        const div = document.createElement('div');
        const img = document.createElement('img');
        div.classList.add('mr-10');
        img.setAttribute('src', require('../assets/icons/check-mark.svg'));
        div.append(img);
        li.insertBefore(div, li.firstChild);
      });
      product.description = descriptionContainer.outerHTML;
    });
  }
};
</script>

<style lang="scss" scoped>
  .subscription-info {
    margin-bottom: 24px;
  }
  .plan-button {
    margin-top: auto;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }
  .primary-btn, .opacity-btn {
    max-width: 100%;
  }

  .opacity-btn {
    border: 2px solid #000000;
    color: #000;
    &:hover {
      transition:.5s;
      background: #1A1B1D;
      border-color: #1A1B1D;
      color: white;
    }
    &.footer-btn {
      position: relative;
      width: 21%;
      margin-left: 65px;
      &:hover:before{
      border-color: white;
      transition: .5s;
      }
      &::before{
        content: '';
        border-top: 2px solid black;
        border-left: 2px solid black;
        width: 10px;
        height: 10px;
        transform: rotate(-45deg);
      }
    }
    &:disabled {
      background-color: transparent;
      opacity: 0.2;
      &:hover {
        color: #000000;
      }
    }
  }

  .wid {
    width: 100%;
  }

  .w-148 {
    width: 148px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mt-34 {
    margin-top: 34px;
  }

  .mt-15 {
    margin-top: 15px;
  }

  .mt-57 {
    margin-top: 57px;
  }

  .mt-50 {
    margin-top: 50px;
  }

  .mt-45 {
    margin-top: 45px;
  }

  .opacities {
    opacity: .2;
  }

  .primary-btn {
    background: #000;
    &:hover {
      background: #1A1B1D;
    }
    &:disabled {
      opacity: .2;
    }
  }

  .throught {
    text-decoration: line-through;
    font-size: 14px;
    font-family: 'Spartan SemiBold';
    margin-right: 5px;
  }

  .plan-block {
    border-radius: 0;
    &__head{
      background: #000;
    }
  }

  .body-1 {
    background: #CAF6ED;
  }

  .body-2 {
    background: #98ECDA;
  }

  .body-3 {
    background: #65E3C9;
  }

  .typography-11 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
    line-height: 150%;
    letter-spacing: 0.04em;
    color: #000000;
  }

  .typography-8 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.08em;
    color: #000000 !important;
  }

  .typography-15 {
    font-weight: bold;
    font-size: 36px;
    line-height: 26px;
    letter-spacing: 0.05em;
    color: #000000;
    text-transform: uppercase;
  }

  .typography-13 {
    font-weight: bold;
    font-size: 28px;
    line-height: 26px;
    letter-spacing: 0.05em;
    color: #000000;
    text-transform: uppercase;
  }

  .typography-14 {
    font-family: 'Spartan Bolder';
    font-style: normal;
    font-size: 18px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
  }

  .check-width {
    width: 20px;
    height: 20px;
  }

  .column {
    flex-direction: column;
  }

  .pad {
    padding: 15px 24px 12px 24px;
  }

  .blacked {
    background: #000;
  }

  .grayed {
    color: #4C4C4C;
  }

  .blued {
    color: #698AFF !important;
    &:hover {
      color: #60BCE3 !important;
      transition: .5s;
    }
  }

  .color-link {
    color: white !important;
  }

  .row {
    margin-left: -12px;
    margin-right: -12px;
  }

  .col {
    padding-left: 12px;
    padding-right: 12px;
  }

  .active {
    color: #000 !important;
    opacity: 1;
  }

  .checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
    margin: 10px 0 0 20px;
  }

  .checkbox + label {
    position: relative;
    margin-right: 70px;
  }

  .checkbox + label:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 32px;
    border-radius: 18px;
    background: #6AD6B8;
    transition: .2s;
  }

  .checkbox + label:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #FFF;
    box-shadow: 0 2px 5px rgba(0,0,0,.3);
    transition: .2s;
  }

  .checkbox:checked + label:before {
    background: #6AD6B8;
  }

  .checkbox:checked + label:after {
    left: 20px;
  }

  .decoration {
    text-decoration: line-through;
    text-decoration-thickness: 2px;
    opacity: 0.7;
  }

  .ent-img {
    max-width: 325px;
  }
</style>
